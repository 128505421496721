import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import { useStyles } from '../constants'

const Explore = () => {
  const classes = useStyles()
  return (
    <Link to='/future-cardinals/campus-tour' style={{ textDecoration: 'none' }}>
      <div
        className={classes.exploreWrapper}
        style={{
          backgroundImage: 'url(https://api.mlsem.org/static/img/explore-back.webp)',
        }}
      >
        <div className={classes.exploreContent}>
          <div style={{ paddingBottom: '.5em' }}>Explore Our Campus Now</div>
          <Button color='primary' variant='contained'>
            LET&apos;S GO
          </Button>
        </div>
        {/* <div className={classes.explorePlay}>play it</div> */}
      </div>
    </Link>
  )
}

export default Explore
