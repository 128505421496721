import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import React from 'react'
import StepsBack from '../res/img/steps-back.webp'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../constants'

function StepsToCardinal() {
  const classes = useStyles()
  return (
    <div
      style={{
        backgroundImage: `url(${StepsBack})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        padding: '3em 0',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth='lg'>
        <Typography variant='h2' align='center' className={`${classes.fancyText} ${classes.stepsHeader}`}>
          become a cardinal
        </Typography>
        <div className={classes.stepsWrapper}>
          <Link to='/future-cardinals/request-info-packet' className={classes.stepsLink}>
            <div className={classes.stepsButton}>
              <Typography variant='h4' className={classes.fancyText}>
                Request
                <br />
                Info
              </Typography>
            </div>
          </Link>
          <Link to='/future-cardinals/visit' className={classes.stepsLink}>
            <div className={classes.stepsButton}>
              <Typography variant='h4' className={classes.fancyText}>
                Visit
              </Typography>
            </div>
          </Link>
          <Link to='/future-cardinals/admissions' className={classes.stepsLink}>
            <div className={classes.stepsButton}>
              <Typography variant='h4' className={classes.fancyText}>
                Apply
              </Typography>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default StepsToCardinal
