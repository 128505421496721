import React, { Fragment, useEffect, useState } from 'react'

import Container from '@material-ui/core/Container'
import CoverPicture from './CoverPicture'
import FatFooter from './FatFooter'
import Paper from '@material-ui/core/Paper'
//import SAppBar from './SectionAppBar'
import MAppBar from './MainAppBar'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { useStyles } from '../constants'
import SubNavBar from './SubNavBar'
import Drawer from '@material-ui/core/Drawer'
import SearchResults from './SearchResults'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'

const SectionPage = ({ showAppBar, showFooter }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    fetching: false,
    fetched: false,
    page: null,
    previewOpen: false,
    previewData: null,
  })
  const { section, slug } = useParams()

  const toggleDrawer = () => {
    setState({ ...state, previewOpen: !!!state.previewOpen })
  }

  const handleSearchClick = st => {
    setState({
      ...state,
      previewOpen: true,
      previewData: (
        <div style={{ height: 'calc(100vh - 5em)', overflowY: 'auto' }}>
          <Typography variant='h4'>
            Searching mlsem.org for:
            <br />
            &#34;{st}&#34;
          </Typography>
          <SearchResults searchTerm={st} />
        </div>
      ),
    })
  }

  const fetchPage = async slug => {
    setState({ ...state, fetching: true })
    const res = await fetch('https://api.mlsem.org/api/section_page' + slug, {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then(res => setState({ ...state, fetching: false, fetched: true, page: res }))
      .catch(error => null)
  }

  useEffect(() => {
    if (state.fetching !== true) {
      //&& state.fetched !== true) {
      if (section) {
        fetchPage(`/${section}-cardinals/${slug}`)
      } else {
        fetchPage(`/${slug}`)
      }
    }
  }, [section, slug])

  if (state.fetching) return null
  if (!state.page) return null

  return (
    <Fragment>
      <MAppBar handleSearchClick={handleSearchClick} />
      <div className={classes.lbSectionPageWrapper}>
        <CoverPicture height='375px' />
        <SubNavBar />
      </div>
      <Container maxWidth='lg'>
        <div style={{ position: 'relative', top: '-5em' }}>
          <Paper
            style={{
              padding: '1em',
              backgroundColor: 'rgba(0,0,0,0.6)',
            }}
          >
            <Typography
              variant='h4'
              className={classes.fancyText}
              style={{ color: '#fff', fontSize: '2.4em', fontWeight: 'bolder' }}
            >
              {state.page.title}
            </Typography>
          </Paper>
        </div>
        <div
          style={{ position: 'relative', top: '-2em' }}
          dangerouslySetInnerHTML={{
            __html: state.page.content,
          }}
        ></div>
      </Container>
      <Drawer
        anchor='right'
        ModalProps={{
          onEscapeKeyDown: () => {
            toggleDrawer()
          },
        }}
        PaperProps={{
          className: classes.drawerPaper,
        }}
        className={classes.mainDrawer}
        open={state.previewOpen}
      >
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <IconButton onClick={toggleDrawer}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ padding: '0 2em', overflowY: 'auto', overflowX: 'none' }}>
            {state.previewData}
          </Grid>
        </Grid>
      </Drawer>
      <FatFooter />
    </Fragment>
  )
}

export default SectionPage
