import './index.css'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import CalendarPage from './components/CalendarPage'
import CampusTourPage from './components/CampusTour'
import HomePage from './components/HomePage'
import React from 'react'
import ReactGA from 'react-ga'
import ScrollToTop from './components/ScrollToTop'
import SectionHome from './components/SectionHome'
import SectionPage from './components/SectionPage'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { responsiveFontSizes } from '@material-ui/core'

const trackingId = 'UA-10913142-2'
ReactGA.initialize(trackingId)

let t = createMuiTheme({
  palette: {
    primary: {
      main: '#b40000',
    },
    secondary: {
      main: '#7986cb',
    },
  },
})

const theme = responsiveFontSizes(t)

function App() {
  React.useEffect(() => {
    ReactGA.pageview(location.pathname)
  })
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path='/'>
              <HomePage />
            </Route>
            <Route
              exact
              path='/future-cardinals/campus-tour'
              render={() => {
                return <CampusTourPage />
              }}
            />
            <Route exact path='/:section(future|current|always)-cardinals'>
              <SectionHome hideInfoBoxes hideNews hideTestimonials hideInfoGraphics />
            </Route>
            <Route exact path='/:section(future|current|always)-cardinals/:slug(.*)'>
              <SectionPage showAppBar showFooter hideInfoGraphics hideTestimonials hideNews hideInfoBoxes />
            </Route>
            <Route exact path='/calendar' component={CalendarPage} />
            <Route exact path='/admin' render={() => (window.location.href = '//api.mlsem.org/admin/')} />
            <Route
              exact
              path='/athletics'
              render={() => (window.location.href = 'https://www.arbiterlive.com/Teams?entityId=14605')}
            />
            <Route
              path='/:section'
              render={() => {
                return (
                  <SectionHome hideInfoBoxes={true} hideNews={true} hideTestimonials={true} hideInfoGraphics={true} />
                )
              }}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
