import React, { useState } from 'react'
import { useStyles } from '../constants'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import homeLogo from '../res/img/home-logo.png'
import mls from '../res/img/home-mls.png'
import wels from '../res/img/home-wels.png'
import Search from './Search'
export default ({ handleSearchClick }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    menuAnchor: null,
    searchValue: '',
  })

  const handleSearch = v => {
    handleSearchClick(v)
    setState({ ...state, searchValue: '' })
  }
  return (
    <div className={classes.root}>
      <AppBar position='fixed'>
        <Container maxWidth='lg'>
          <Toolbar>
            <a href='/'>
              <img src={homeLogo} alt='MLS' border='0' />
            </a>
            <div className={classes.titleImages}>
              <img src={mls} alt='Michigan Lutheran Seminary' />
              <br />
              <img src={wels} alt='Wisconsin Evangelical Lutheran Synod' />
            </div>
            <div className={classes.grow} />
            <Search handleSearch={handleSearch} />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}
