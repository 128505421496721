import React, { useEffect, useState } from 'react'

import { useStyles } from '../constants'

export default ({ height, section }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    fetching: false,
    fetched: false,
    coverPictures: null,
  })

  const fetchCoverPictures = async () => {
    setState({ ...state, fetching: true })
    let url_ex = ['support', 'calendar', 'athletics'].indexOf(section) > -1 ? `/${section}` : `/${section}-cardinals`
    if (!section) url_ex = ''
    const res = await fetch('https://api.mlsem.org/api/carousel_images' + url_ex, {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then((res) => setState({ ...state, fetching: false, fetched: true, coverPictures: res.data }))
      .catch((error) => null)
  }

  useEffect(() => {
    if (state.fetching !== true && state.fetched !== true) {
      fetchCoverPictures()
    }
  })

  if (state.fetching) return null
  if (state.coverPictures === null) return null
  if (state.coverPictures.length < 1) return null

  const c_classes = section === 'athletics' ? classes.athleticsCoverPictureWrapper : classes.coverPictureWrapper

  const coverPicture = state.coverPictures[Math.floor(Math.random() * Math.floor(state.coverPictures.length))]

  return (
    <div className={c_classes} style={{ width: '100%', height: height }}>
      <div
        style={{
          backgroundPosition: 'center center',
          backgroundImage: `url(http://api.mlsem.org${coverPicture.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: height,
        }}
      ></div>
    </div>
  )
}
