import React, { Fragment, useState } from 'react'

import Carousel from './Carousel'
import Close from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Experience from './Experience'
import Explore from './Explore'
import FastFacts from './FastFacts'
import FatFooter from './FatFooter'
import Grid from '@material-ui/core/Grid'
import Happenings from './Happenings'
import IconButton from '@material-ui/core/IconButton'
import MAppBar from './MainAppBar'
import SearchResults from './SearchResults'
import StepsToCardinal from './StepsToCardinal'
import SubNavBar from './SubNavBar'
import Typography from '@material-ui/core/Typography'
import mlsLogo from '../res/img/mlslogo.png'
import { useStyles } from '../constants'
import Slide from '@material-ui/core/Slide'
import Paper from '@material-ui/core/Paper'

export default () => {
  const classes = useStyles()
  const [state, setState] = useState({
    drawerOpen: false,
    drawerData: null,
    covidMsg: true,
  })

  const toggleDrawer = () => {
    setState({ ...state, drawerOpen: !!!state.drawerOpen })
  }

  const handleNewsClick = (id, title, body, picture, caption) => {
    setState({
      ...state,
      drawerOpen: true,
      drawerData: (
        <div>
          <Typography variant='h4'>{title}</Typography>
          <Divider style={{ margin: '1em 0' }} />
          <div className={classes.newsContentWrapper}>
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {picture ? <img src={picture} alt={caption || ''} className={classes.newsContentPicture} /> : ''}
          </div>
        </div>
      ),
    })
  }

  const handleSearchClick = (st) => {
    setState({
      ...state,
      drawerOpen: true,
      drawerData: (
        <div style={{ height: 'calc(100vh - 5em)', overflowY: 'auto' }}>
          <Typography variant='h4'>
            Searching mlsem.org for:
            <br />
            &#34;{st}&#34;
          </Typography>
          <SearchResults searchTerm={st} />
        </div>
      ),
    })
  }

  return (
    <Fragment>
      {/*}
      <Slide direction='up' in={state.covidMsg} mountOnEnter unmountOnExit>
        <div
          style={{
            position: 'fixed',
            zIndex: '1000000',
            bottom: '0px',
            display: state.covidMsg ? 'block' : 'none',
          }}
        >
          <Paper className={classes.covidBox} elevation='15'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                right: '0px',
                top: '0px',
              }}
            >
              <IconButton onClick={() => setState({ ...state, covidMsg: false })}>
                <Close style={{ color: '#fcfcfc' }} />
              </IconButton>
            </div>
            <a
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
              target='_blank'
              href='https://issuu.com/michiganlutheranseminary/docs/return_to_campus_plan'
            >
              <Typography align='center' style={{ color: '#fcfcfc', fontSize: '1.6em' }}>
                Cardinals Back!
              </Typography>
              <Divider style={{ margin: '4px' }} />
              <Typography align='center' style={{ color: '#fcfcfc', fontSize: '1.2em' }}>
                Road to Re-opening
              </Typography>
              <Divider style={{ margin: '4px' }} />
              <Typography align='center' style={{ color: '#fcfcfc', fontSize: '0.9em' }}>
                Read MLS&apos; plan to safely return to school amid COVID-19
              </Typography>
            </a>
          </Paper>
        </div>
      </Slide>{*/}
      <MAppBar handleSearchClick={handleSearchClick} />
      {/* <div style={{ height: '64px' }} /> */}
      <div className={classes.lbCarouselWrapper}>
        <Carousel />
        <SubNavBar />
        {/* <LookingBoxes /> */}
      </div>
      <div style={{ height: '3em' }} />
      <FastFacts />
      <div style={{ height: '3em' }} />
      <StepsToCardinal />
      <Happenings newsClick={handleNewsClick} />
      <Explore />
      <Experience />

      {/* <div className={classes.lighterGrey} style={{ padding: '6em 0', margin: '4em 0' }}>
				<Container maxWidth='lg'>
					<Grid container>
						<Grid item xs={12} sm={9} lg={10}>
							<Typography variant='h4' className={classes.fancyText}>
								TAKE THE NEXT STEP
							</Typography>
							<Divider style={{ width: '90%', backgroundColor: '#fff', margin: '1em 0' }} />
							<Typography variant='body1' component='p' className={classes.nextStepText}>
								Interested in MLS and not sure what to do next? We'd love to point you in the right direction!
							</Typography>
							<Typography variant='body1' component='p' className={classes.nextStepText}>
								Click "More Info," and we'll mail you a packet of imformation about MLS.
							</Typography>
							<Typography variant='body1' component='p' className={classes.nextStepText}>
								Click "Visit MLS," and we'll help you look at some options for getting on our campus. We love having
								visitors any time!
							</Typography>
							<Typography variant='body1' component='p' className={classes.nextStepText}>
								Click "Apply Now," and you'll be directed to our online application. Apply for free today!
							</Typography>
						</Grid>
						<Grid item xs={6} sm={3} lg={2} style={{ margin: 'auto 0 0 0' }}>
							<Button
								variant='outlined'
								className={classes.fancyText}
								onClick={() => (window.location.href = '/future-cardinals/request-info-packet/')}
								style={{ width: '100%', background: '#fff', border: `1px rgb(116,116,116) solid`, margin: '3px 0' }}>
								More Info
							</Button>
							<br />
							<Button
								variant='outlined'
								className={classes.fancyText}
								onClick={() => (window.location.href = '/future-cardinals/visit/')}
								style={{ width: '100%', background: '#fff', border: `1px rgb(116,116,116) solid`, margin: '3px 0' }}>
								Visit MLS
							</Button>
							<br />
							<Button
								variant='outlined'
								className={classes.fancyText}
								onClick={() => (window.location.href = '/future-cardinals/admissions/')}
								style={{ width: '100%', background: '#fff', border: `1px rgb(116,116,116) solid`, margin: '3px 0' }}>
								Apply Now
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div> */}
      <div className={classes.greyback}>
        <Container maxWidth='lg'>
          <div className={classes.mission} style={{ padding: '3em 0' }}>
            <img src={mlsLogo} alt='MLS' className={classes.mlsLogo} />
            <Typography
              variant='h4'
              className={`${classes.fancyText} ${classes.blackText}`}
              style={{ paddingTop: '.25em' }}
            >
              OUR MISSION
            </Typography>
            <hr />
            <Typography variant='body2' className={`${classes.blackText} ${classes.missionText}`}>
              The special purpose of Michigan Lutheran Seminary is to prepare high school students for the public
              ministry of the gospel, encouraging them to enroll in the WELS College of Ministry, Martin Luther College.
            </Typography>
          </div>
        </Container>
      </div>
      <FatFooter />
      <Drawer
        anchor='right'
        ModalProps={{
          onEscapeKeyDown: () => {
            toggleDrawer()
          },
        }}
        PaperProps={{
          className: classes.drawerPaper,
        }}
        className={classes.mainDrawer}
        open={state.drawerOpen}
      >
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <IconButton onClick={toggleDrawer}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ padding: '0 2em', overflowY: 'auto', overflowX: 'none' }}>
            {state.drawerData}
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  )
}
