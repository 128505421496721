import React, { Fragment, useState } from 'react'
import { useStyles } from '../constants'
import SAppBar from './SectionAppBar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FatFooter from './FatFooter'

const LINKLIST = [
    {
        id: 0,
        vid: 'JeeTo9vrp6s',
        label: 'Commons',
    },
    {
        id: 1,
        vid: 'Q9zDYu67u2E',
        label: 'Chem Lab',
    },
    {
        id: 2,
        vid: '31DYjrHvP4m',
        label: 'Dining Hall',
    },
    {
        id: 3,
        vid: '8JsqPAfpVm9',
        label: 'Physics Lab',
    },
    {
        id: 4,
        vid: 'BiRNCq7UEx6',
        label: 'Science Lab',
    },
    {
        id: 5,
        vid: 'uiyPudC2Ksd',
        label: 'Main Office',
    },
    {
        id: 6,
        vid: 'pQDCkG1wFpR',
        label: 'Boys Bathroom',
    },
    {
        id: 7,
        vid: '2DPb2RQY6UN',
        label: 'Quad Dorm Room',
    },
    {
        id: 8,
        vid: 'xeuUZXHNBiP',
        label: 'Third Floor Bathroom and Showers',
    },
    {
        id: 9,
        vid: 'LNmu3P6n4tC',
        label: 'Double Dorm',
    },
    {
        id: 10,
        vid: '7a22AUkt3XP',
        label: 'Game, Cardio, and Laundry',
    },
    {
        id: 11,
        vid: '4BAXinpR4yX',
        label: 'Biology Classroom',
    },
    {
        id: 12,
        vid: 'bgbbXPU4KK4',
        label: 'Standard Classroom',
    },
    {
        id: 13,
        vid: 'kRM1KkkNHJP',
        label: 'Choir Room',
    },
    {
        id: 14,
        vid: 'joFLejD94Dm',
        label: 'Band Room',
    },
    {
        id: 15,
        vid: 'ADqywwmh5od',
        label: 'Library',
    },
    {
        id: 16,
        vid: 'Rcv3f2QpBqD',
        label: 'Chapel',
    },
    {
        id: 17,
        vid: 'S94oxgM96yV',
        label: 'Trophy Hall',
    },
    {
        id: 18,
        vid: 'Us53BTww7Jo',
        label: 'Music Hall & Organ',
    },
    {
        id: 19,
        vid: 'afKQzWSpAhW',
        label: 'Weight Room',
    },
    {
        id: 20,
        vid: '8CyRmxYjDkF',
        label: 'Gym',
    }
]

export default () => {
    const classes = useStyles()
    const [state, setState] = useState({
        selectedVideoId: 0,
    })

    const handleChange = e => {
        setState({...state, selectedVideoId: e.target.value})
    }

    return (
        <Fragment>
            <SAppBar />
            <Container maxWidth='lg' style={{marginTop: '96px'}}>
                <Typography variant='h3' className={ classes.fancyText }>
                    Campus Tour
                </Typography>
                <Divider style={ { margin: '1em 0' } }/>
                <div style={ { display: 'flex', justifyContent: 'center' } }>
                    <FormControl className={classes.campusTourSelect}>
                        <InputLabel id='select-video'>Select a video</InputLabel>
                        <Select
                            labelId='select-video'
                            value={state.selectedVideoId}
                            onChange={handleChange}
                        >
                            { LINKLIST.map(li => (
                                <MenuItem value={ li.id } key={ li.id }>{ li.label }</MenuItem>
                            ))}

                            </Select>
                    </FormControl>
                </div>
                <Divider style={{margin: '1em 0'}} />
                <iframe
                    title='Campus Tour'
                    width="100%"
                    height="405"
                    src={ `https://my.matterport.com/show/?m=${LINKLIST.filter(li => li.id === state.selectedVideoId)[0].vid}` }
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                    allow="vr">
                </iframe>
                <div>
                    <p style={ { textAlign: 'center' } }><em><strong>We would like to thank the <a href="https://mls-foundation.org/" target="_blank" rel="noreferrer noopener">MLS Foundation</a> and the MLS Booster Club for their generosity with the project!</strong></em></p>
<p style={{textAlign: 'center'}}><img src="//api.mlsem.org/static/media/uploads/17884390_1207786842658531_2272605070043105135_n.png" alt="" width="150" />&nbsp; &nbsp;<img src="//api.mlsem.org/static/media/uploads/cropped-cropped-picture1.gif" alt="" width="150" /></p>
                </div>
            </Container>
            <FatFooter/>
        </Fragment>
    )
}
