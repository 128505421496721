import React, { useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../constants'
import CircularProgress from '@material-ui/core/CircularProgress'

export default ({ searchTerm }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    searchResults: null,
    fetching: false,
    fetched: false,
  })

  const fetchSearchResults = async st => {
    setState({ ...state, fetching: true })

    let searchParams = new URLSearchParams()
    searchParams.append('st', st.trim())

    const res = await fetch('https://api.mlsem.org/api/search?' + searchParams.toString(), {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then(res => setState({ ...state, fetching: false, fetched: true, searchResults: res.r }))
      .catch(error => null)
  }

  useEffect(() => {
    if (state.fetching !== true && state.fetched !== true) {
      fetchSearchResults(searchTerm)
    }
  })

  if (state.fetching)
    return (
      <div style={{ align: 'center', width: '100%' }}>
        <CircularProgress />
      </div>
    )

  if (state.searchResults === null) return null

  const handleClick = s => {
    window.location.href = s
  }

  return (
    <List>
      {state.searchResults.length > 0 ? <div></div> : <ListItem>No search results found!</ListItem>}
      {state.searchResults.map(sr => {
        const is_lead = sr.description.split(' ').length > 30

        return (
          <ListItem key={sr.id}>
            <Card className={classes.searchResultsCard} onClick={() => handleClick(sr.url)}>
              <CardContent>
                <Typography variant='h6'>{sr.title}</Typography>
                <Typography variant='body2'>
                  {sr.description
                    .split(' ')
                    .splice(0, 30)
                    .join(' ')}
                  {is_lead ? '...' : ''}
                </Typography>
              </CardContent>
            </Card>
          </ListItem>
        )
      })}
    </List>
  )
}
