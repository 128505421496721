import React, {Fragment} from 'react'
import {faFacebookSquare, faInstagram, faTwitterSquare, faYoutubeSquare} from '@fortawesome/free-brands-svg-icons'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import {Link as ExtLink} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid'
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import mlslogo from '../res/img/mlslogo.png'
import {useStyles} from '../constants'
import whitebox from '../res/img/1x1-white.png'
import whitelogo from '../res/img/mls-logo-white.png'

const FatFooter = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <div className={`${classes.greyback} ${classes.fatFooter}`}>
        <Container maxWidth='lg'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h4' className={classes.fatFooterHeading}>
                  ABOUT US
                </Typography>
              </div>
	  <div className={classes.fatFooterText}>
	  <Link to="/future-cardinals/history" className={classes.fatFooterLink}>
	  History
	  </Link>
	  </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/contact' className={classes.fatFooterLink}>
                  Contact Us
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/current-cardinals/staff-directory' className={classes.fatFooterLink}>
                  Faculty &amp; Staff
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/leadership-governing-board' className={classes.fatFooterLink}>
                  Governing Board
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/school-handbook' className={classes.fatFooterLink}>
                  School Catalog
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/policies-procedures-1' className={classes.fatFooterLink}>
                  Policies &amp; Procedures
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <ExtLink
                  href='https://docs.google.com/document/d/18L4wy_4AVBqHqwd7T65FHR2lrqMwKS3vdi73vGqCFuQ'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.fatFooterLink}
                >
                  Record of Drills Conducted
                </ExtLink>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/promotional-resources' className={classes.fatFooterLink}>
                  Promotional Resources
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h4' className={classes.fatFooterHeading}>
                  ADMISSIONS
                </Typography>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/visit' className={classes.fatFooterLink}>
                  Visit MLS
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/admissions' className={classes.fatFooterLink}>
                  Apply to MLS
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/request-info-packet' className={classes.fatFooterLink}>
                  Request Info Packet
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/tuition-fees' className={classes.fatFooterLink}>
                  Financial Aid
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/junior-cardinals' className={classes.fatFooterLink}>
                  Junior Cardinals
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/transcripts' className={classes.fatFooterLink}>
                  Transcripts
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Typography variant='h4' className={classes.fatFooterHeading}>
                    ACTIVITIES
                  </Typography>
                </div>
                <div className={classes.fatFooterText}>
                  <ExtLink
                    href='https://gofan.co/app/events/627225?schoolId=MI4533'
                    target='_blank'
                    rel='noreferrer'
                    className={classes.fatFooterLink}
                  >
                    Athletic Passes
                  </ExtLink>
                </div>
                <div className={classes.fatFooterText}>
                  <ExtLink
                    href='https://drive.google.com/file/d/1MTtW5vEwJmX6GiCYSlbeRGbfEZjrFJRD/view'
                    target='_blank'
                    rel='noreferrer'
                    className={classes.fatFooterLink}
                  >
                    Concert Choir Schedule
                  </ExtLink>
                </div>
                <div className={classes.fatFooterText}>
                  <ExtLink
                    href='https://sideline.bsnsports.com/schools/michigan/saginaw/michigan-lutheran-seminary'
                    target='_blank'
                    rel='noreferrer'
                    className={classes.fatFooterLink}
                  >
                    BSN Sideline Store
                  </ExtLink>
                </div>
                <div className={classes.fatFooterText}>
                  <ExtLink
                    href='https://www.mhsaa.com/portals/0/Documents/health%20safety/physical2page.pdf'
                    target='_blank'
                    rel='noreferrer'
                    className={classes.fatFooterLink}
                  >
                    Physical Form
                  </ExtLink>
                </div>
                <div className={classes.fatFooterText}>
                  <Link to='/future-cardinals/summer-camps' className={classes.fatFooterLink}>
                    Summer Camps
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div>
                <Typography variant='h4' className={classes.fatFooterHeading}>
                  CAMPUS LIFE
                </Typography>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/calendar' className={classes.fatFooterLink}>
                  Calendar
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/student-handbook' className={classes.fatFooterLink}>
                  Campus Guide
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/future-cardinals/family-code' className={classes.fatFooterLink}>
                  Family Code
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <Link to='/current-cardinals/faq' className={classes.fatFooterLink}>
                  MLS FAQs
                </Link>
              </div>
              <div className={classes.fatFooterText}>
                <ExtLink
                  href='https://docs.google.com/document/d/1C4TtCtVedMNzL7hb9b58KDAGxmuxsueRSm-Rg8LVDfU/'
                  target='_blank'
                  rel='noreferrer'
                  className={classes.fatFooterLink}
                >
                  School Supply List
                </ExtLink>
              </div>
            </Grid>
          </Grid>
          <div style={{textAlign: 'center', padding: '1em 0'}}>
            <img src={whitebox} style={{width: '17vw', height: '2px', paddingRight: '3em'}} alt='' />
            <img src={whitelogo} style={{verticalAlign: 'middle'}} alt='mls' />
            <img src={whitebox} style={{width: '17vw', height: '2px', paddingLeft: '2.5em'}} alt='' />
          </div>
          <div style={{textAlign: 'center', paddingBottom: '.5em', color: '#fff', fontSize: '2em'}}>
            <ExtLink
              href='https://www.facebook.com/mlsem'
              target='_blank'
              rel='noreferrer'
              className={classes.fatFooterLink}
            >
              <FontAwesomeIcon icon={faFacebookSquare} style={{padding: '0 5px'}} />
            </ExtLink>
            <ExtLink
              href='https://www.twitter.com/mlsem'
              target='_blank'
              rel='noreferrer'
              className={classes.fatFooterLink}
            >
              <FontAwesomeIcon icon={faTwitterSquare} style={{padding: '0 5px'}} />
            </ExtLink>
            <ExtLink
              href='https://www.youtube.com/channel/UCP9uz1kabApSG7-utD7XfXA'
              target='_blank'
              rel='noreferrer'
              className={classes.fatFooterLink}
            >
              <FontAwesomeIcon icon={faYoutubeSquare} style={{padding: '0 5px'}} />
            </ExtLink>
            <ExtLink
              href='https://www.instagram.com/mlseminary'
              target='_blank'
              rel='noreferrer'
              className={classes.fatFooterLink}
            >
              <FontAwesomeIcon icon={faInstagram} style={{padding: '0 5px'}} />
            </ExtLink>
          </div>
          <div
            style={{
              textAlign: 'center',
              color: '#3a3a3a',
              background: '#fff',
              border: '1px solid #3a3a3a',
              borderRadius: '4px',
              padding: '1em',
              minWidth: '240px',
              maxWidth: '50vw',
              margin: '0 auto',
            }}
          >
            <Typography variant='h6'>Subscribe on YouTube!</Typography>

            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={mlslogo} alt='MLS' height='50px' width='50px' />
              <div>
                <Typography variant='body2'>Michigan Lutheran Seminary</Typography>

                <ExtLink
                  href='https://www.youtube.com/channel/UCP9uz1kabApSG7-utD7XfXA?sub_confirmation=1'
                  style={{textDecoration: 'none'}}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button variant='contained' color='primary'>
                    YouTube
                  </Button>
                </ExtLink>
              </div>
            </div>
            {/* <div className="g-ytsubscribe" data-channelid="UCP9uz1kabApSG7-utD7XfXA" data-layout="full" data-count="hidden"></div> */}
          </div>
        </Container>
      </div>

      <div className={classes.darkerGrey}>
        <Container maxWidth='lg'>
          <Grid container spacing={3} className={classes.footerContactInfo}>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant='body2'>MICHIGAN LUTHERAN SEMINARY</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant='body2'>2777 HARDIN STREET, SAGINAW, MI 48602</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Typography variant='body2'>T: (989) 793-1010</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Typography variant='body2'>F: (989) 401-7334</Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={2}>
              <Link to='/future-cardinals/contact' className={classes.fatFooterLink}>
                <Typography variant='body2'>CONTACT US</Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  )
}

export default FatFooter
