import React, {Fragment, useState} from 'react'

import {Carousel} from 'react-responsive-carousel'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {useStyles} from '../constants'

const StudentsFF = () => {
  return (
    <Grid container spacing={0} style={{background: '#fff'}}>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/students/International.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/students/Male_Female.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/students/Ratio.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/students/States.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>

    </Grid>
  )
}

const AcademicsFF = () => {
  return (
    <Grid container spacing={0} style={{background: '#fff'}}>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/academics/CHROMEBOOKS.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/academics/SERVICE GROUPS.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/academics/GPA.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/academics/CLASS SIZE.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
    </Grid>
  )
}

const ArtsFF = () => {
  return (
    <Grid container spacing={0} style={{background: '#fff'}}>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/arts/CHOIRS.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/arts/KEYBOARD INSTRUCTION.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/arts/PIANO ROOMS.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/arts/CSP.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
    </Grid>
  )
}

const StudentLifeFF = () => {
  return (
    <Grid container spacing={0} style={{background: '#fff'}}>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/studentlife/Airports.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/studentlife/Counseling.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/studentlife/Founded.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/studentlife/Dorms.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
    </Grid>

  )
}

const AthleticsFF = () => {
  return (
    <Grid container spacing={0} style={{background: '#fff'}}>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/athletics/BOYS SPORTS.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url(//api.mlsem.org/static/media/fastfacts/athletics/Champions.jpg)',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/athletics/Conference.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} style={{minHeight: '209px'}}>
        <div
          style={{
            background: 'url("//api.mlsem.org/static/media/fastfacts/athletics/GIRLS SPORTS.jpg")',
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        ></div>
      </Grid>
    </Grid>

  )
}

export default () => {
  const classes = useStyles()
  const [state, setState] = useState({
    selectedFF: 0,
  })

  return (
    <Fragment>
      <Container maxWidth='lg'>
        <Grid container>
          <Grid item xs={12} md={2}>
            <div className={`${classes.fastFactsLabel} ${classes.fancyText}`}>Fast Facts</div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div
              className={
                state.selectedFF === 0 ? `${classes.fastFactHeaderActive}` : `${classes.fastFactHeaderInactive}`
              }
              onClick={() => setState({...state, selectedFF: 0})}
            >
              Students
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div
              className={
                state.selectedFF === 1 ? `${classes.fastFactHeaderActive}` : `${classes.fastFactHeaderInactive}`
              }
              onClick={() => setState({...state, selectedFF: 1})}
            >
              Academics
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div
              className={
                state.selectedFF === 2 ? `${classes.fastFactHeaderActive}` : `${classes.fastFactHeaderInactive}`
              }
              onClick={() => setState({...state, selectedFF: 2})}
            >
              Arts
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div
              className={
                state.selectedFF === 3 ? `${classes.fastFactHeaderActive}` : `${classes.fastFactHeaderInactive}`
              }
              onClick={() => setState({...state, selectedFF: 3})}
            >
              Athletics
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <div
              className={
                state.selectedFF === 4 ? `${classes.fastFactHeaderActive}` : `${classes.fastFactHeaderInactive}`
              }
              onClick={() => setState({...state, selectedFF: 4})}
            >
              Student Life
            </div>
          </Grid>
        </Grid>
        <Grid container justify='center' alignItems='center' spacing={2}>
          <Grid item xs={12}>
            <Carousel
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              dynamicHeight={false}
              swipeable={false}
              selectedItem={state.selectedFF}
            >
              <StudentsFF />
              <AcademicsFF />
              <ArtsFF />
              <AthleticsFF />
              <StudentLifeFF />
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}
