import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  // menuButton: {
  // 	marginRight: theme.spacing(2),
  // },
  title: {
    flexGrow: 1,
  },
  titleImages: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingLeft: '1em',
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // width: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    // height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    // width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    color: '#fff!important',
  },
  support: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      paddingRight: '1em',
    },
  },
  infocell: {
    textAlign: 'center',
  },
  greyback: {
    background: '#acacac',
  },
  schoolStats: {
    background: '#b4000',
    color: '#ffffff',
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  mlsLogo: {
    display: 'inline-block',
    position: 'relative',
  },
  mission: {
    textAlign: 'center',
  },
  newsCard: {
    minWidth: '100%',
    cursor: 'pointer',
  },
  darkerGrey: {
    background: '#747474',
    color: '#fff',
    padding: '1.5em 0',
  },
  lighterGrey: {
    background: `rgb(206,206,206)`,
  },
  fatFooter: {
    marginTop: '3em',
    padding: '3em 0 0 0',
    color: '#fff',
  },
  fatFooterText: {
    paddingTop: `${theme.spacing(1)}px!important`,
    paddingBottom: `${theme.spacing(1)}px!important`,
    [theme.breakpoints.up('lg')]: {
      marginLeft: `${theme.spacing(2)}px`,
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '1.3em',
    },
  },
  fatFooterHeading: {
    fontFamily: 'Trajan Pro Regular!important',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '2.5em!important',
    },
  },
  footerContactInfo: {
    textAlign: 'center',
  },
  fatFooterLink: {
    color: '#fff!important',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:visited': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
  fancyText: {
    fontFamily: 'Trajan Pro Regular!important',
  },
  nextStepText: {
    padding: '.2em 0',
  },
  blackText: {
    color: '#000!important',
  },
  missionText: {
    fontSize: '1.2em!important',
    letterSpacing: '.125em!important',
    textAlign: 'center',
  },
  lbCarouselWrapper: {
    height: '600px',
    marginTop: '64px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '54px',
    },
  },
  lbSectionHomeWrapper: {
    height: '425px',
    marginTop: '56px',
  },
  lbSectionPageWrapper: {
    height: '375px',
    marginTop: '56px',
  },
  lbWrapper: {
    position: 'absolute',
    width: '100%',
  },
  carouselWrapper: {
    position: 'absolute',
  },
  coverPictureWrapper: {
    position: 'absolute',
  },
  covidBox: {
    backgroundColor: 'rgb(180,0,0)!important',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  athleticsCoverPictureWrapper: {
    position: 'relative',
  },
  lbBoxes: {
    marginTop: '375px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    marginLeft: '15vw',
    marginRight: '15vw',
  },
  lbSectionBoxes: {
    marginTop: '200px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    marginLeft: '15vw',
    marginRight: '15vw',
  },
  lbContainer: {
    border: '4px white solid ',
    height: '60px',
    textAlign: 'center',
    backgroundColor: 'rgba(180,0,0,.6)',
    padding: '20px 0',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.8)',
      border: '4px #b40000 solid',
      color: '#b40000',
    },
    cursor: 'pointer',
  },
  lbContent: {
    fontSize: '1.35em',
    fontWeight: 'bold',
  },
  lbSubContent: {
    fontSize: '.85em',
    fontWeight: 'bold',
    paddingTop: '6px',
  },
  tagLine: {
    marginLeft: '5vw',
    marginRight: '5vw',
    textAlign: 'center',
    color: '#fff',
    fontSize: '2.4em',
    padding: '10px 3px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0,0,0,0.4)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  selectedToolbarSection: {
    backgroundColor: '#fff',
    color: '#b40000',
  },
  pagePreviewWrapper: {
    padding: '3em 0',
  },
  pageDescription: {
    padding: '2em',
  },
  previewReadMore: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  calendarGenericEvent: {
    color: '#000',
    backgroundColor: 'rgb(172,172,172)',
    borderColor: '#555555',
  },
  calendarSportEvent: {
    color: '#fff',
    backgroundColor: 'rgba(180,0,0,0.6)',
    borderColor: '#555555',
  },
  calendarVarsitySportEvent: {
    color: '#fff',
    backgroundColor: '#b40000',
    borderColor: '#555555',
  },
  sectionAppBarLink: {
    color: '#fff',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pagePreview: {
    padding: '1em',
  },
  styledLink: {
    color: '#3c3c3c',
    textDecoration: 'none',
  },
  pagePreviewContent: {
    /* height: '90vh',
    overflow: 'auto',
   		width: 'calc(100vw - 2em)',
		[theme.breakpoints.up('md')]: {
			width: 'calc(60vw - 2em)',
},*/
  },
  previewDrawer: {
    [theme.breakpoints.up('md')]: {
      left: 'unset !important',
    },
    /*   width: 'calc(100vw - 2em)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(60vw - 2em)',
    },
margin: '1em',*/
  },

  fastFactHeaderInactive: {
    backgroundColor: 'rgb(206,206,206)',
    color: '#b40000',
    textAlign: 'center',
    padding: '1em 0',
    fontFamily: 'Trajan Pro Regular!important',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      margin: '0 1px',
    },
  },
  fastFactsLabel: {
    backgroundColor: '#b40000',
    fontWeight: 'bolder',
    color: '#fff',
    textAlign: 'center',
    padding: '1em 0',
    fontFamily: 'Trajan Pro Regular!important',
    [theme.breakpoints.up('md')]: {
      marginRight: '1px',
    },
  },
  fastFactHeaderActive: {
    backgroundColor: 'rgb(244,227,184)',
    color: '#b40000',
    textAlign: 'center',
    padding: '1em 0',
    fontFamily: 'Trajan Pro Regular!important',
    [theme.breakpoints.up('md')]: {
      margin: '0 1px',
    },
  },
  stepsWrapper: {
    display: 'flex',
    width: '100%',
    paddingTop: '4em',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  stepsButton: {
    color: '#fff',
    border: '2px solid #b40000',
    width: '200px',
    height: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#b40000',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1em 0',
    },
  },
  stepsHeader: {
    color: '#fff',
  },
  subNavBar: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  subNavBarContainer: {
    position: 'absolute',
    top: '56px',
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
    [theme.breakpoints.down('md')]: {
      right: '0',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
  },
  subNavContent: {
    backgroundColor: 'rgba(160,160,160,.85)',
    [theme.breakpoints.up('lg')]: {
      margin: '0 4em',
      flexDirection: 'row',
    },
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '0 0 10px 10px',
  },
  subNavLink: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.1em',
    [theme.breakpoints.down('md')]: {
      padding: '.8em',
    },
  },
  subNavDivider: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  subNavLinkContainer: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '80%',
    },
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  fastFactsGridItem: {
    cursor: 'pointer',
  },
  homeNavWrapper: {
    backgroundColor: '#b40000',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    margin: '.5em 0',
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  homeNavTextWrapper: {
    width: '100%',
    display: 'flex',
    maxHeight: '41px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  homeNavOuterWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  homeNavImagesWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },
  homeNavImage: {
    maxWidth: '100%',
    height: 'auto',
    marginLeft: '2em',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 'inherit',
    },
  },
  exploreWrapper: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '408px',
    backgroundRepeat: 'no-repeat',
    marginTop: '3em',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
  },
  exploreContent: {
    padding: '0 0 2em 2em',
    fontSize: '1.4em',
    fontWeight: 'bolder',
    color: '#fff',
  },
  explorePlay: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
    minWidth: '200px',
    height: '100vh',
    overflowY: 'auto',
  },
  mainDrawer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
      left: 'unset!important',
    },
  },
  newsContentPicture: {
    [theme.breakpoints.up('md')]: {
      float: 'right',
      paddingLeft: '1em',
    },
    paddingBottom: '1em',
    maxWidth: '300px',
    maxHeight: '300px',
  },
  newsContentPictureWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  newsContentWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  searchGoButton: {
    color: '#fff',
  },
  searchResultsCard: {
    width: '100%',
    cursor: 'pointer',
  },
  fastFactCarousel: {
    height: '209px',
  },
  stepsLink: {
    textDecoration: 'none',
    color: '#fff',
  },
  calendarList: {
    listStyle: 'none',
    paddingLeft: '0px',
  },
  campusTourSelect: {
    width: '40vw',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  coverPictureOverlay: {
    minWidth: '320px',
    maxWidth: '400px',
    padding: '2em',
    position: 'absolute',
    bottom: '0px',
    backgroundColor: 'rgba(93, 93, 93, 0.8)',
    [theme.breakpoints.up('lg')]: {
      right: '0px',
      borderRadius: '8px 0 0 0',
    },
    borderRadius: '0 8px 0 0',
  },
}))
