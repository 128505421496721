import React from 'react'
import { useStyles } from '../constants'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LatestNews from './LatestNews'
import CutoutR from '../res/img/cutout-r.png'
import CutoutL from '../res/img/cutout-l.png'
import WishList from '../res/img/wishlist.png'
import WishlistText from '../res/img/wishlist-text.png'
import WhyMLS from '../res/img/whymls.png'
import WhyMLSText from '../res/img/whymls-text.png'
import Calendar from '../res/img/calendar.png'
import CalendarText from '../res/img/calendar-text.png'
import Photos from '../res/img/photos.png'
import PhotosText from '../res/img/photos-text.png'
import Athletics from '../res/img/athletics.png'
import AthleticsText from '../res/img/athletics-text.png'
import Link from '@material-ui/core/Link'

export default ({ newsClick }) => {
  const classes = useStyles()
  return (
    <Container maxWidth='lg' style={{ marginTop: '2em' }}>
      <Grid container>
        <Grid item md={12} lg={6}>
          <Typography align='center' variant='h4' className={classes.fancyText}>
            Happening @ Seminary
          </Typography>
          <LatestNews handleClick={newsClick} />
        </Grid>
        <Grid item md={12} lg={6} style={{ width: '100%', height: '100%' }}>
          <div className={classes.homeNavOuterWrapper}>
            <div className={classes.homeNavWrapper}>
              <img src={CutoutL} alt='l' />
              <div className={classes.homeNavImagesWrapper}>
                <Link href='https://youtu.be/94dkM9UPfB4'>
                  <img src={WhyMLS} alt='Why MLS' className={classes.homeNavImage} />
                </Link>
                <div className={classes.homeNavTextWrapper}>
                  <Link href='https://youtu.be/94dkM9UPfB4'>
                    <img src={WhyMLSText} alt='Why MLS' style={{ maxWidth: '100%', height: 'auto' }} />
                  </Link>
                </div>
              </div>
              <img src={CutoutR} alt='r' />
            </div>
            <div className={classes.homeNavWrapper}>
              <img src={CutoutL} alt='l' />
              <div className={classes.homeNavImagesWrapper}>
                <Link href='/future-cardinals/support/wish-list'>
                  <img src={WishList} alt='Wish List' className={classes.homeNavImage} />
                </Link>
                <div className={classes.homeNavTextWrapper}>
                  <Link href='/future-cardinals/support/wish-list'>
                    <img
                      src={WishlistText}
                      alt='Wish List'
                      style={{ margin: '0 2em', maxWidth: '100%', height: 'auto' }}
                    />
                  </Link>
                </div>
              </div>
              <img src={CutoutR} alt='r' />
            </div>
            <div className={classes.homeNavWrapper}>
              <img src={CutoutL} alt='l' />
              <div className={classes.homeNavImagesWrapper}>
                <Link href='/calendar'>
                  <img src={Calendar} alt='Calendar' className={classes.homeNavImage} />
                </Link>
                <div className={classes.homeNavTextWrapper}>
                  <Link href='/calendar'>
                    <img
                      src={CalendarText}
                      alt='Calendar'
                      style={{ margin: '0 2em', maxWidth: '100%', height: 'auto' }}
                    />
                  </Link>
                </div>
              </div>
              <img src={CutoutR} alt='r' />
            </div>
            <div className={classes.homeNavWrapper}>
              <img src={CutoutL} alt='l' />
              <div className={classes.homeNavImagesWrapper}>
                <Link href='https://mlsem.smugmug.com'>
                  <img src={Photos} alt='Photos' className={classes.homeNavImage} />
                </Link>
                <div className={classes.homeNavTextWrapper}>
                  <Link href='https://mlsem.smugmug.com'>
                    <img src={PhotosText} alt='Photos' style={{ margin: '0 2em', maxWidth: '100%', height: 'auto' }} />
                  </Link>
                </div>
              </div>
              <img src={CutoutR} alt='r' />
            </div>
            <div className={classes.homeNavWrapper}>
              <img src={CutoutL} alt='l' />
              <div className={classes.homeNavImagesWrapper}>
                <Link href='/future-cardinals/athletics'>
                  <img src={Athletics} alt='Athletics' className={classes.homeNavImage} />
                </Link>
                <div className={classes.homeNavTextWrapper}>
                  <Link href='/future-cardinals/athletics'>
                    <img
                      src={AthleticsText}
                      alt='Athletics'
                      style={{ margin: '0 2em', maxWidth: '100%', height: 'auto' }}
                    />
                  </Link>
                </div>
              </div>
              <img src={CutoutR} alt='r' />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
