import '../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css'
import 'lazysizes'

import React, { useEffect, useState } from 'react'

import { Carousel } from 'react-responsive-carousel'
import { useStyles } from '../constants'
import Typography from '@material-ui/core/Typography'

function MLSCarousel() {
  const classes = useStyles()
  const [state, setState] = useState({
    fetching: false,
    fetched: false,
    carouselImages: null,
  })

  const fetchCarousel = async () => {
    const res = await fetch('https://api.mlsem.org/api/carousel_images', {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then(res => setState({ ...state, fetching: false, fetched: true, carouselImages: res.data }))
      .catch(error => null)
  }

  useEffect(() => {
    if (state.fetched !== true) {
      fetchCarousel()
    }
  })

  if (state.fetching) return null
  if (state.carouselImages === null) return null
  if (state.carouselImages.length < 1) return null
  return (
    <div className={classes.carouselWrapper} style={{ width: '100%', height: '600px' }}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={7000}
        transitionTime={2000}
        showThumbs={false}
      >
        {state.carouselImages.map(ci => (
          <div style={{ height: 600, width: '100vw' }} key={ci.id}>
            <img
              data-src={`https://api.mlsem.org${ci.url}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              className='lazyload'
              alt='carousel'
            />
            {ci.title ? (
              <div className={classes.coverPictureOverlay}>
                <Typography
                  variant='h5'
                  component='div'
                  style={{ color: '#fff', textAlign: 'left', fontWeight: '800', fontsize: '1.8em' }}
                >
                  {ci.title}
                </Typography>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    marginTop: '24px',
                    color: '#fff',
                    fontSize: '1.1em',
                    fontWeight: 'bold',
                    letterSpacing: '1px',
                    lineHeight: '1em',
                  }}
                >
                  {ci.body}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default MLSCarousel
