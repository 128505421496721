import React, { useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Moment from 'react-moment'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../constants'

const NewsItem = ({ title, body, bodyText, lead, picture, caption, id, clickHandler, when, where }) => {
  const classes = useStyles()
  const [state] = useState({
    newsBody: null,
    showingAll: false,
  })

  return (
    <ListItem>
      <Card className={classes.newsCard} onClick={() => clickHandler(id, title, body, picture, caption)}>
        <CardContent>
          <Typography variant='h5' style={{ color: '#b40000' }} className={`${classes.fancyText}`}>
            {title}
          </Typography>
          <div>
            <Typography component='div' variant='body2' style={{ lineHeight: '1.25' }}>
              {state.showingAll === true ? <div>{bodyText}</div> : <div>{lead}...</div>}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Typography component='div' variant='body2' style={{ marginTop: '1em' }}>
                {when ? (
                  <div>
                    <span style={{ color: '#b40000' }}>Date:</span> <Moment format='MM/DD/YYYY'>{when}</Moment>{' '}
                    <Moment format='h:mma'>{when}</Moment>
                    <br />
                  </div>
                ) : (
                  ''
                )}
                {where ? (
                  <div>
                    <span style={{ color: '#b40000' }}>Location:</span> {where}
                  </div>
                ) : (
                  ''
                )}
              </Typography>
              {state.showingAll !== true ? (
                <div style={{ textAlign: 'right', color: '#b40000', marginTop: '.8em', cursor: 'pointer' }}>
                  Read more
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </ListItem>
  )
}

export default ({ handleClick, section }) => {
  const [state, setState] = useState({
    latestNews: null,
    fetching: false,
    fetched: false,
  })

  const fetchNews = async () => {
    let url_ex = ['support', 'calendar', 'athletics'].indexOf(section) > -1 ? `/${section}` : `/${section}-cardinals`
    if (!section) url_ex = ''
    setState({ ...state, fetching: true })

    const res = await fetch('https://api.mlsem.org/api/latest_news', {
   // const res = await fetch('https://cardinalapp.mlsem.org/messenger/api/v1/announcements/public', {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then((res) => {
        setState({ ...state, fetching: false, fetched: true, latestNews: res.data })
      })
      .catch((error) => null)
  }
  useEffect(() => {
    if (state.fetching !== true && state.fetched !== true) {
      fetchNews()
    }
  }, [])

  if (state.fetching)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size='3em' />
      </div>
    )
  if (state.latestNews === null || state.latestNews === undefined) return 'still null'
  return (
    <List>
      {state.latestNews.map((ln) => (
        <NewsItem
          title={ln.title}
          body={ln.announcement}
          bodyText={ln.announcement_text}
          lead={ln.announcement_text
            .split(' ')
            .splice(0, 30)
            .join(' ')}
          picture={ln.picture}
          caption={ln.picture_caption}
          id={ln.id}
          clickHandler={handleClick}
          when={ln.event_datetime}
          where={ln.location}
          key={ln.id}
        />
      ))}
    </List>
  )
}
