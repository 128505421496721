import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import Close from '@material-ui/icons/Close'
import CoverPicture from './CoverPicture'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import FatFooter from './FatFooter'
import IconButton from '@material-ui/core/IconButton'
//import SAppBar from './SectionAppBar'
import MAppBar from './MainAppBar'
import SubNavBar from './SubNavBar'
import SectionPage from './SectionPage'
import SectionPagePreview from './SectionPagePreview'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../constants'
import SearchResults from './SearchResults'
import Grid from '@material-ui/core/Grid'

const SectionHome = props => {
  const {hideInfoGraphics, hideInfoBoxes, hideNews, hideTestimonials} = props
  const [state, setState] = useState({
    showLookingBoxes: false,
    coverPictureHeight: '375px',
    previewOpen: false,
    previewData: null,
    drawerOpen: false,
    drawerData: null,
    fetching: false,
    fetched: false,
  })
  const classes = useStyles()

  const handleSearchClick = st => {
    setState({
      ...state,
      previewOpen: false,
      drawerOpen: true,
      drawerData: (
        <div style={{ height: 'calc(100vh - 5em)', overflowY: 'auto' }}>
          <Typography variant='h4'>
            Searching mlsem.org for:
            <br />
            &#34;{st}&#34;
          </Typography>
          <SearchResults searchTerm={st} />
        </div>
      ),
    })
  }

  const checkRedirect = async section => {
    if (section.indexOf('future') > -1 || section.indexOf('current') > -1 || section.indexOf('always') > -1) return
    setState({ ...state, fetching: true })
    const res = await fetch('https://api.mlsem.org/api/check_redirect/' + section, {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res
      .json()
      .then(res => {
        setState({ ...state, fetching: false, fetched: true })
        if (res.is_redirect === true) window.location.href = res.to
      })
      .catch(error => console.log(error))
  }

  const handleReadMoreClick = (e, previewData) => {
    setState({ ...state, previewData: previewData, previewOpen: !!!state.previewOpen })
  }

  const toggleDrawer = () => {
    setState({ ...state, drawerOpen: !!!state.drawerOpen })
  }

  const handleToggleDrawer = () => {
    setState({ ...state, previewOpen: !!!state.previewOpen })
  }

  const { section } = useParams()
  const match = useRouteMatch()

  useEffect(() => {
    if (state.fetching !== true) {
      // && state.fetched !== true) {
      checkRedirect(section)
    }
  }, [section])

  if (state.fetching) return null

  return (
    <Fragment>
      <MAppBar handleSearchClick={handleSearchClick} />
      <Switch>
        <Route path={`${match.path}/:slug`}>
          <SectionPage />
        </Route>
        <Route path={match.path}>
          <Fragment>
            <div className={classes.lbSectionHomeWrapper}>
              <CoverPicture section={section} height='425px' />
              <SubNavBar />
              {/* <LookingBoxes section={section} /> */}
            </div>
            {/* {hideInfoBoxes ? '' : <InfoBoxes section={section} />}
						{hideNews ? '' : <LatestNews section={section} />}
						{hideInfoGraphics ? '' : <InfoGraphic section={section} />}
						{hideTestimonials ? '' : <Testimonial section={section} />} */}
            <SectionPagePreview section={section} handleReadMoreClick={handleReadMoreClick} />
          </Fragment>
        </Route>
      </Switch>
      <Drawer
        anchor='right'
        ModalProps={{
          onEscapeKeyDown: () => {
            toggleDrawer()
          },
        }}
        PaperProps={{
          className: classes.drawerPaper,
        }}
        className={classes.mainDrawer}
        open={state.drawerOpen}
      >
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <IconButton onClick={toggleDrawer}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ padding: '0 2em', overflowY: 'auto', overflowX: 'none' }}>
            {state.drawerData}
          </Grid>
        </Grid>
      </Drawer>
      <Drawer
        anchor='right'
        className={classes.previewDrawer}
        open={state.previewOpen}
        onBackdropClick={handleToggleDrawer}
        onEscapeKeyDown={handleToggleDrawer}
        PaperProps={{ className: classes.drawerPaper }}
      >
        {state.previewData ? (
          <div className={classes.pagePreview}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h6'>{state.previewData.title}</Typography>
              <div>
                <IconButton onClick={handleToggleDrawer} style={{ float: 'right' }}>
                  <Close />
                </IconButton>
              </div>
            </div>
            <Divider />
            <div
              className={classes.pagePreviewContent}
              dangerouslySetInnerHTML={{ __html: state.previewData.content }}
            ></div>
          </div>
        ) : (
          ''
        )}
      </Drawer>
      <FatFooter />
    </Fragment>
  )
}


export default SectionHome
