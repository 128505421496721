import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import Support from '../res/img/support.png'
import { useStyles } from '../constants'

function SubNavBar() {
  const classes = useStyles()
  return (
    <div className={classes.subNavBar}>
      <div className={classes.subNavBarContainer}>
        <div className={classes.subNavContent}>
          <div className={classes.subNavLinkContainer}>
            <div className={`${classes.subNavLink} ${classes.fancyText}`}>
              <Link style={{ color: '#fff', textDecoration: 'none' }} to='/current-cardinals'>
                Current Students
              </Link>
            </div>
            <div className={classes.subNavDivider}>|</div>
            <div className={`${classes.subNavLink} ${classes.fancyText}`}>
              <Link style={{ color: '#fff', textDecoration: 'none' }} to='/future-cardinals'>
                Future Students
              </Link>
            </div>
            <div className={classes.subNavDivider}>|</div>
            <div className={`${classes.subNavLink} ${classes.fancyText}`}>
              <a
                style={{ color: '#fff', textDecoration: 'none' }}
                href='https://cardinalapp.mlsem.org'
                target='_blank'
                rel='noopener noreferrer'
              >
                Cardinal App
              </a>
            </div>
            <div className={classes.subNavDivider}>|</div>
            <div className={`${classes.subNavLink} ${classes.fancyText}`}>
              <a
                style={{ color: '#fff', textDecoration: 'none' }}
                href='https://www.youtube.com/c/MichiganLutheranSeminary'
                target='_blank'
                rel='noopener noreferrer'
              >
                Live Streaming
              </a>
            </div>
          </div>
          <div className={classes.subNavLink}>
            <a
              style={{ textDecoration: 'none' }}
              href='https://community.mlsem.org'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button variant='contained'>
                <img
                  src={Support}
                  alt='Support MLS'
                  style={{
                    paddingRight: '1em',
                  }}
                />
                Support&nbsp;MLS
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubNavBar
