import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import mlslogo from '../res/img/mlslogo.png'
import support from '../res/img/support.png'
import { useStyles } from '../constants'

const SectionAppBar = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position='fixed'>
        <Container maxWidth='lg'>
          <Toolbar className={classes.root}>
            <Link to='/'>
              <img src={mlslogo} alt='MLS' style={{ paddingRight: '2em' }} />
            </Link>
            <div className={classes.root}></div>
            <Typography variant='h6' className={`${classes.root} ${classes.sectionAppBarLink}`}>
              <Link to='/future-cardinals' className={classes.sectionAppBarLink}>
                Future Cardinals
              </Link>
            </Typography>
            <Typography variant='h6' className={`${classes.root} ${classes.sectionAppBarLink}`}>
              <Link to='/current-cardinals' className={classes.sectionAppBarLink}>
                Current Cardinals
              </Link>
            </Typography>
            <Typography variant='h6' className={`${classes.root} ${classes.sectionAppBarLink}`}>
              <Link to='/always-cardinals' className={classes.sectionAppBarLink}>
                Always Cardinals
              </Link>
            </Typography>
            <div className={classes.support}>
              <Button
                color='default'
                variant='contained'
                onClick={() => (window.location.href = 'https://community.mlsem.org')}
              >
                <img src={support} alt='Support MLS' style={{ paddingRight: '1em' }} />
                <Typography variant='body1' component='b' className={classes.fancyText} noWrap>
                  Support MLS
                </Typography>
              </Button>
            </div>
            {/* <IconButton edge='end' color='inherit' onClick={handleMenu}>
							<MoreIcon />
						</IconButton>
						<Menu anchorEl={state.menuAnchor} open={Boolean(state.menuAnchor)} keepMounted onClose={handleClose}>
							<MenuItem>
								<a href='/athletics'>Athletics</a>
							</MenuItem>
							<MenuItem>
								<a href='https://cardinalapp.mlsem.org/cardinal_casts'>Cardinal Casts</a>
							</MenuItem>
							<MenuItem>
								<a href='https://cardinalapp.mlsem.org'>Cardinal App</a>
							</MenuItem>
						</Menu> */}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default SectionAppBar
