import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../constants'

const Experience = () => {
  const classes = useStyles()
  return (
    <Container maxWidth='lg'>
      <Grid container justify='center'>
        <Grid item xs={12} align='center' style={{ padding: '3em 0 2em 0' }}>
          <Typography variant='h6' className={classes.fancyText} style={{ color: '#b40000' }}>
            Experience Michigan Lutheran Seminary
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4} align='center' style={{ paddingBottom: '3em' }}>
          <div
            style={{
              backgroundImage: 'url(https://api.mlsem.org/static/img/home-videos.webp)',
              backgroundSize: 'contain',
              width: '100%',
              height: '228px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
          <Typography variant='body2' style={{ margin: '2em 0 1em 0' }}>
            View videos from a wide variety of campus
            <br />
            events as well as promotional.
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => (window.location.href = 'https://www.youtube.com/channel/UCP9uz1kabApSG7-utD7XfXA')}
          >
            Watch Videos
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4} align='center' style={{ paddingBottom: '3em' }}>
          <div
            style={{
              backgroundImage: `url(https://api.mlsem.org/static/img/home-galleries.webp)`,
              backgroundSize: 'contain',
              width: '100%',
              height: '228px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
          <Typography variant='body2' style={{ margin: '2em 0 1em 0' }}>
            Enjoy galleries of beautiful pictures
            <br />
            captured over the years at MLS.
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => (window.location.href = 'https://mlsem.smugmug.com')}
          >
            View Photos
          </Button>
        </Grid>
        <Grid item xs={12} lg={4} align='center' style={{ paddingBottom: '3em' }}>
          <div
            style={{
              backgroundImage: `url(https://api.mlsem.org/static/img/home-streams.webp)`,
              backgroundSize: 'contain',
              width: '100%',
              height: '228px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
          <Typography variant='body2' style={{ margin: '2em 0 1em 0' }}>
            View live streams of chapel, concerts,
            <br />
            sporting events and more!
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => (window.location.href = 'https://www.youtube.com/c/MichiganLutheranSeminary')}
          >
            Watch Streams
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Experience
