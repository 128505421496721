import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useStyles } from '../constants'

const PagePreview = ({ pageId, title, description, imgUrl, slug, xtraClass, handleReadMoreClick, content }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.pagePreviewWrapper} ${xtraClass ? classes.lighterGrey : ''}`}>
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={10}>
            <Typography variant='h5' className={`${classes.fancyText}`}>
              <a href={`/${slug}`} className={classes.styledLink}>
                {title}
              </a>
            </Typography>

            <hr />
            <div className={classes.pageDescription}>
              <Typography variant='body1'>
                {description ? description : "This page doesn't have a description. Click to see more."}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={2} className={classes.previewReadMore}>
            {imgUrl ? (
              <div>
                <img src={`//api.mlsem.org${imgUrl}`} style={{ maxHeight: '200px' }} alt='' />
              </div>
            ) : (
              ''
            )}
            <Button
              fullWidth
              variant='outlined'
              style={{ border: '1px solid rgb(116,116,116)', background: '#fff' }}
              onClick={e => handleReadMoreClick(e, { id: pageId, title: title, content: content })}
            >
              Read More
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ({ section, handleReadMoreClick }) => {
  const [state, setState] = useState({
    fetching: false,
    fetched: false,
    pages: null,
  })

  const fetchPages = async () => {
    setState({ ...state, fetching: true })
    let url_ex = ['support', 'calendar', 'athletics'].indexOf(section) > -1 ? `/${section}` : `/${section}-cardinals`
    if (!section) url_ex = ''
    const res = await fetch('https://api.mlsem.org/api/section_pages' + url_ex, {
      method: 'GET',
      mode: 'cors',
      contentType: 'application/json',
    })
    res.json().then(res => setState({ ...state, fetching: false, fetched: true, pages: res.section_pages }))
  }

  useEffect(() => {
    if (state.fetching !== true) {
      // && state.fetched !== true) {
      fetchPages()
    }
  }, [section])

  if (state.fetching) return null
  if (!state.pages) return null

  return state.pages.map((p, i) => (
    <PagePreview
      key={p.id}
      imgUrl={p.preview_picture}
      title={p.title}
      description={p.description}
      slug={p.slug}
      content={p.content}
      xtraClass={clsx({ '1': i % 2 === 0 })}
      handleReadMoreClick={handleReadMoreClick}
      pageId={p.id}
    />
  ))
}
