import React, { Fragment, useState } from 'react'

import Calendar from './Calendar'
import Close from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import FatFooter from './FatFooter'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Moment from 'react-moment'
import SAppBar from './SectionAppBar'
import Typography from '@material-ui/core/Typography'

function CalendarPage() {
  const [state, setState] = useState({
    drawerOpen: false,
    drawerContent: null,
  })
  const handleEventClick = content => {
    setState({
      ...state,
      drawerOpen: true,
      drawerContent: (
        <Fragment>
          <Typography variant='h4' style={{ paddingBottom: '16px' }}>
            {content.title}
          </Typography>
          <Divider />
          {content.allDay ? (
            <Typography variant='h6' style={{ padding: '8px' }}>
              All Day
            </Typography>
          ) : content.start ? (
            <Fragment>
              <Typography variant='h6' style={{ padding: '8px' }}>
                Begin
              </Typography>
              <Typography variant='body2' style={{ padding: '8px 16px' }}>
                <Moment format='LLLL'>{content.start}</Moment>
              </Typography>
              {content.end ? (
                <Fragment>
                  <Typography variant='h6' style={{ padding: '8px' }}>
                    End
                  </Typography>
                  <Typography variant='body2' style={{ padding: '8px 16px' }}>
                    <Moment format='LLLL'>{content.end}</Moment>
                  </Typography>
                </Fragment>
              ) : (
                ''
              )}
            </Fragment>
          ) : (
            ''
          )}
          {content.location ? (
            <Fragment>
              <Typography variant='h6' style={{ padding: '8px' }}>
                Location
              </Typography>
              <Typography variant='body2' style={{ padding: '8px 16px' }}>
                <Link
                  href={`//www.google.com/maps/search/?api=1&query=${encodeURI(content.location)}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {content.location}
                </Link>
              </Typography>
            </Fragment>
          ) : (
            ''
          )}
          {content.description ? (
            <Fragment>
              <Typography variant='h6' style={{ padding: '8px' }}>
                Description
              </Typography>
              <Typography variant='body2' style={{ padding: '8px 16px' }}>
                {content.description}
              </Typography>
            </Fragment>
          ) : (
            ''
          )}
        </Fragment>
      ),
    })
  }
  const toggleDrawer = () => setState({ ...state, drawerOpen: !state.drawerOpen })
  return (
    <Fragment>
      <SAppBar />
      {/*<ListCalendar /> */}
      <Calendar eventClickHandler={handleEventClick} />
      <FatFooter />
      <Drawer anchor='right' open={state.drawerOpen} ModalProps={{ onEscapeKeyDown: () => toggleDrawer() }}>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <IconButton onClick={toggleDrawer}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ padding: '0 2em', overflowY: 'auto', overflowX: 'none' }}>
            {state.drawerContent}
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  )
}

export default CalendarPage
