import React, { useState } from 'react'
import { useStyles } from '../constants'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'

export default ({ handleSearch }) => {
	const classes = useStyles()
	const [state, setState] = useState({
		searchValue: '',
	})

	const handlePress = k => {
		if (k.key === 'Enter') {
			handleSearch(state.searchValue)
			setState({...state, searchValue: ''})
		}
	}

	const handleClick = () => {
		handleSearch(state.searchValue)
		setState({...state, searchValue: ''})
	}

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon} style={{ height: '100%' }}>
				<SearchIcon />
			</div>
			<InputBase
				placeholder='Search...'
				classes={{
					root: classes.root,
					input: classes.inputInput,
				}}
				inputProps={{ 'aria-label': 'search' }}
				onKeyPress={handlePress}
				value={state.searchValue}
				onChange={e => setState({ ...state, searchValue: e.target.value })}
				style={{ marginLeft: '2.75em' }}
			/>
			<Button
				style={{ color: '#fff' }}
				className={classes.searchGoButton}
				onClick={e => handleClick()}>
				Go
			</Button>
		</div>
	)
}
