import Container from '@material-ui/core/Container'
import FullCalendar from '@fullcalendar/react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
import { useStyles } from '../constants'
import './Calendar.css'

const GAPIKey = 'AIzaSyBOkXGKoar8Ixe25uy-yW3G1Z9QTHntdRY'

function Calendar({ eventClickHandler }) {
  const classes = useStyles()
  return (
    <div>
      <Container maxWidth='lg' style={{ marginTop: '6em' }}>
        <FullCalendar
          style={{ paddingTop: '2em' }}
          googleCalendarApiKey={GAPIKey}
          eventClick={info => {
            info.jsEvent.preventDefault()
            eventClickHandler({
              start: info.event.start,
              end: info.event.end,
              title: info.event.title,
              location: info.event.extendedProps.location,
              description: info.event.extendedProps.description,
              url: info.event.url,
              allDay: info.event.allDay,
            })
          }}
          eventSources={[
            {
              /* academic */
              googleCalendarId: 'mlsem.org_d9nnrqe43680gsi827mo2kp0qs@group.calendar.google.com',
              className: classes.calendarGenericEvent,
            },
            { googleCalendarId: 'mlsem.org_c5idtvpqvbqj01ub1f2en9vk8o@group.calendar.google.com' },
            {
              /* frosh boys basketball activity */
              //googleCalendarId: 'mlsem.org_ckknurjnfdlbjp614kd0lstm9k@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* frosh boys basketball events */
              googleCalendarId: 'mlsem.org_54i5ugt1dujhq8ptk9g107rti4@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* jv boys baseball activity */
              //googleCalendarId: 'mlsem.org_2ku9nikj4c08b8aad7vhs8a5h0@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* jv boys baseball events */
              googleCalendarId: 'mlsem.org_4nqkqsjkjqss43i5212e6skjdg@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* boys jv basketball activity */
              //googleCalendarId: 'mlsem.org_ljcq62tfo8iiqqbk9k5a7un4o4@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* boys jv basketball events */
              googleCalendarId: 'mlsem.org_5kdg7srj4greg79r3ks3sas5dg@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* jv football activities */
              //googleCalendarId: 'mlsem.org_odvb6g5vp2q9rasu67u79k3fck@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* jv football events */
              googleCalendarId: 'mlsem.org_udq2m250g0hmo2ttmsvq78k22g@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* varsity baseball activities */
              //googleCalendarId: 'mlsem.org_mo5d243pbum5jh6anala0egpgk@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* varsity baseball events */
              googleCalendarId: 'mlsem.org_muglg0vv6gql8m3rrjp4ckpjjk@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* boys varsity basketball activities */
              //googleCalendarId: 'mlsem.org_ef5f250ifbdpsucljhk6b14pgo@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* boys varsity baseketball events */
              googleCalendarId: 'mlsem.org_o6sod53sd9qhshqbirjoed4lts@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* varsity football activities */
              //googleCalendarId: 'mlsem.org_n3uknfcl81u91oep3eqsolu0uo@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* varsity football events */
              googleCalendarId: 'mlsem.org_uj2nl00v00vqnr5rk9ddrb9iq4@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* wrestling activities */
              //googleCalendarId: 'mlsem.org_0sp3tqemnccnga19oam2p90mk8@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* wrestling events */
              googleCalendarId: 'mlsem.org_vlohnrngjnutkskap444732d6g@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* concert choir activities */
              //googleCalendarId: 'mlsem.org_cjp1kb3et9bg6efj99uep80nck@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* cross country activities */
              //googleCalendarId: 'mlsem.org_n497eca8lph0s8d1hqi3qg7fv8@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* cross country events */
              googleCalendarId: 'mlsem.org_52oki9n52hrv2qpiru55udin1s@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* CSP Activities */
              //googleCalendarId: 'mlsem.org_sria0oi1iava1l08n3i68319rs@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* fine arts events */
              googleCalendarId: 'mlsem.org_i8mslfrfcf9eed2up0455km9ec@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* frosh girls basketball activities */
              //googleCalendarId: 'mlsem.org_gmg3153l4spvjvum6lbnugn4u8@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* frosh girls basketball events */
              googleCalendarId: 'mlsem.org_76eaf6f70do9c90389clna6kpk@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* frosh girls volleyball activities */
              //googleCalendarId: 'mlsem.org_momaccetmdh1m88jaaq1dudi50@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* frosh girls volleyball events */
              googleCalendarId: 'mlsem.org_1atnp3lm7eb0ffpns2jssj0d4k@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* jv girls basketball activities */
              //googleCalendarId: 'mlsem.org_gjfn7g2n6dcflm8m9mkht729s8@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* jv girls basketball events */
              googleCalendarId: 'mlsem.org_5a41vg2na7qgqrinaor3r1chs4@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* jv girls softball events */
              googleCalendarId: 'mlsem.org_1ka9hge6kbi57orsnf5de2rh04@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* jv girls volleyball activities */
              //googleCalendarId: 'mlsem.org_lo5pn1qnjbi7pm7bci85d3bfns@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* jv girls volleyball events */
              googleCalendarId: 'mlsem.org_55tt8u486k6r88gct9jbsv9l3g@group.calendar.google.com',
              className: classes.calendarSportEvent,
            },
            {
              /* varsity girls basketball activities */
              //googleCalendarId: 'mlsem.org_oe5cv7o98ndcm30tfo78j5l1kg@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* varsity girls basketball events */
              googleCalendarId: 'mlsem.org_s9o60uf9tmnvu61sdm1k5jv4k4@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* varsity girls softball activity */
              //googleCalendarId: 'mlsem.org_g6odnmjudqnufckjdgd5cfaoho@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* varsity girls softball event */
              googleCalendarId: 'mlsem.org_hr6id74m1511958jj4gsqcjgoc@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* varsity girls volleyball activities */
              //googleCalendarId: 'mlsem.org_lh7sa4vvhkdp1trf738mr6gfmg@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* varsity girls volleyball events */
              googleCalendarId: 'mlsem.org_q6gtlf3a28f7asukvllo7qmsjo@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* HSH activity */
              //googleCalendarId: 'mlsem.org_m1gflvaau8ofnnrlqpdqbp2rdc@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* misc */
              googleCalendarId: 'mlsem.org_a4nta19iu65a84reht2f31mek8@group.calendar.google.com',
              className: classes.calendarGenericEvent,
            },
            {
              /* poms activity */
              //googleCalendarId: 'mlsem.org_a9igm1ocrdjs4us342cfkkgbg8@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* poms events */
              googleCalendarId: 'mlsem.org_ivm1nmq70f837da5p6qg9hjqo8@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* track and field activities */
              //googleCalendarId: 'mlsem.org_n3qfu9b1q6e8snqvojiljlei8o@group.calendar.google.com',
              //className: 'school-calendar-activity'
            },
            {
              /* track and field events */
              googleCalendarId: 'mlsem.org_hdf8vd8bv951slmbkei88s5m70@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
            {
              /* admissions events */
              googleCalendarId: 'mlsem.org_q0htg30odduhjnur40oht0o2r8@group.calendar.google.com',
              className: classes.calendarGenericEvent,
            },
            {
              /* hockey events */
              googleCalendarId: 'mlsem.org_88ugodjo22i4tu13iu47i5a6uk@group.calendar.google.com',
              className: classes.calendarVarsitySportEvent,
            },
          ]}
          views={{
            agendaOneDay: {
              type: 'agenda',
              duration: { days: 1 },
              buttonText: '1 day',
            },
            listDay: {
              buttonText: '1 day',
            },
          }}
          header={{
            left: 'title',
            center: 'prev,next',
            right: 'dayGridMonth,dayGridWeek,listDay',
          }}
          defaultView='dayGridMonth'
          plugins={[dayGridPlugin, listPlugin, googleCalendarPlugin]}
        />
      </Container>

      <Container maxWidth='lg'>
        <div style={{ padding: '2em 0' }}>
          <Typography variant='h5'>Adding MLS calendars to a personal device</Typography>
          <p>
            One can save time visiting the MLS website by adding MLS calendars to a personal device, primarily a smart
            phone or tablet. Once an MLS calendar is added to one’s personal calendar device, all items listed, or
            eventually listed, on that MLS calendar will update on the personal calendar.
          </p>
          <p>
            To add an MLS calendar to one’s personal device, please view this pdf “
            <a
              href='https://docs.google.com/a/mlsem.org/document/d/1Bt_JAADPUaaFx9CQOhsZdv78iUHLcAZxqtFpfbQE1KQ/edit'
              target='_blank'
              rel='noopener noreferrer'
            >
              Adding An MLS Calendar
            </a>
            ” for directions on adding MLS calendars to various devices.
          </p>
        </div>
      </Container>
      <Container maxWidth='lg'>
        <Grid container>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant='h6'>Academic Calendars</Typography>
            <ul className={classes.calendarList}>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_d9nnrqe43680gsi827mo2kp0qs%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Academic Calendar
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_q0htg30odduhjnur40oht0o2r8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Admissions’ Calendar
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_a4nta19iu65a84reht2f31mek8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Main Misc.
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant='h6'>Boys' Athletics Calendars</Typography>
            <ul className={classes.calendarList}>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_d9nnrqe43680gsi827mo2kp0qs%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Basketball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_54i5ugt1dujhq8ptk9g107rti4%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_mrdl86k1f020miqv70f2go0540%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cardinal Casts
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_n497eca8lph0s8d1hqi3qg7fv8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cross Country Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_52oki9n52hrv2qpiru55udin1s%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cross Country Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_88ugodjo22i4tu13iu47i5a6uk%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Hockey Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_2ku9nikj4c08b8aad7vhs8a5h0%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Baseball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_4nqkqsjkjqss43i5212e6skjdg%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Baseball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_ljcq62tfo8iiqqbk9k5a7un4o4%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Basketball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_5kdg7srj4greg79r3ks3sas5dg%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_odvb6g5vp2q9rasu67u79k3fck%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Football Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_udq2m250g0hmo2ttmsvq78k22g%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Football Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_mo5d243pbum5jh6anala0egpgk%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Baseball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_muglg0vv6gql8m3rrjp4ckpjjk%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Baseball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_ef5f250ifbdpsucljhk6b14pgo%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Basketball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_o6sod53sd9qhshqbirjoed4lts%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_n3uknfcl81u91oep3eqsolu0uo%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Football Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_uj2nl00v00vqnr5rk9ddrb9iq4%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Football Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_n3qfu9b1q6e8snqvojiljlei8o%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Track Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_hdf8vd8bv951slmbkei88s5m70%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Track Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/c_50953630e7e809bfe2d34b6883b066b801c753204cb73bd51079b5eb5b369d94%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Weightlifting Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_0sp3tqemnccnga19oam2p90mk8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Wrestling Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_vlohnrngjnutkskap444732d6g%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Wrestling Events
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6'>Girls' Athletics Calendars</Typography>
            <ul className={classes.calendarList}>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_n497eca8lph0s8d1hqi3qg7fv8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cross Country Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_52oki9n52hrv2qpiru55udin1s%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cross Country Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_gmg3153l4spvjvum6lbnugn4u8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Basketball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_76eaf6f70do9c90389clna6kpk%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_momaccetmdh1m88jaaq1dudi50%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Volleyball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_1atnp3lm7eb0ffpns2jssj0d4k%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Freshman Volleyball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_gjfn7g2n6dcflm8m9mkht729s8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Basketball Actvities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_5a41vg2na7qgqrinaor3r1chs4%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_evipi4jgbss8903jarmiad5vpc%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Softball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_1ka9hge6kbi57orsnf5de2rh04%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Softball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_lo5pn1qnjbi7pm7bci85d3bfns%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Volleyball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_55tt8u486k6r88gct9jbsv9l3g%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  JV Volleyball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_a9igm1ocrdjs4us342cfkkgbg8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Pom-Pons Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_ivm1nmq70f837da5p6qg9hjqo8%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Pom-Pons Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_oe5cv7o98ndcm30tfo78j5l1kg%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Basketball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_s9o60uf9tmnvu61sdm1k5jv4k4%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Basketball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_g6odnmjudqnufckjdgd5cfaoho%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Softball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_hr6id74m1511958jj4gsqcjgoc%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Softball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_n3qfu9b1q6e8snqvojiljlei8o%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Track Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_hdf8vd8bv951slmbkei88s5m70%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Track Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_lh7sa4vvhkdp1trf738mr6gfmg%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Volleyball Activities
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_q6gtlf3a28f7asukvllo7qmsjo%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Varsity Volleyball Events
                </a>
              </li>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/c_50953630e7e809bfe2d34b6883b066b801c753204cb73bd51079b5eb5b369d94%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Weightlifting Activities
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant='h6'>Music &amp; Drama Calendars</Typography>
            <ul className={classes.calendarList}>
              <li>
                <a
                  href='https://calendar.google.com/calendar/ical/mlsem.org_i8mslfrfcf9eed2up0455km9ec%40group.calendar.google.com/public/basic.ics'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Fine Arts
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Calendar.propTypes = {
  eventClickHandler: PropTypes.func.isRequired,
}

export default Calendar
